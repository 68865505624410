<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :loading="loading" @delete="bntDetele">

    <template #title-left>{{ getTitle() }}</template>

    <template #actions>
      <v-btn v-if="flat.deleted" text @click="bntUnDetele">
        Восстановить
      </v-btn>
      <v-btn :disabled="btnOK_disabled" text @click="closeOK">
        Сохранить
      </v-btn>
    </template>


    <v-row>

      <v-col cols="12">
        <v-radio-group
            v-model="flat_edit.apartment"
            row
        >
          <v-radio
              label="Квартира"
              :value=false
          ></v-radio>
          <v-radio
              label="Помещение"
              :value=true
          ></v-radio>
        </v-radio-group>

      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
            v-model.number="flat_edit.entrances"
            label="Подьезд"
            type="number"
            placeholder="3"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
            v-model.number="flat_edit.floor"
            type="number"
            label="Этаж"
            placeholder="12"
        ></v-text-field>
      </v-col>
    </v-row>


  </v.dialog>
</template>

<script>

let normal_data = {}


export default {
  props: {value: Boolean, flat: Object, params: Object},
  data: function () {
    return {
      flat_edit: {apartment: true, floor: null, entrances: null},
      btnOK_disabled: true,
      loading: false,
    }
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        if (!this.value) return // отрабатываем только закрытие формы
        this.$emit('input', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  watch: {
    flat_edit: {
      deep: true, // глубокое наблюдение за объектом
      handler(newValue, oldValue) {
        newValue; oldValue
        if (this.flat_edit.floor === '') this.flat_edit.floor = null
        if (this.flat_edit.entrances === '') this.flat_edit.entrances = null
        this.btnOK_disabled = !this.$lib.isDifferences(this.flat, this.flat_edit)
      }
    }
  },
  methods: {
    bntUnDetele() {
      this.$axios.patch(this.$url.server_project_flat.replace('55555', this.flat.id), {deleted: false}, {zLoader: [this, 'loading']})
          .then(response => {
            this.$lib.restore(this.flat, response.data)
            this.show = false
          })
    },
    bntDetele() {
      this.$axios.delete(this.$url.server_project_flat.replace('55555', this.flat.id), {zLoader: [this, 'loading']})
          .then(response => {
            this.$lib.restore(this.flat, response.data)
            this.show = false
          })
    },
    getTitle() {
      let type = this.flat.apartment ? 'Помещение № ' : 'Квартира № '
      return type + ' ' + this.flat.number
    },
    form_show() {
      this.$lib.restore(this.$data, normal_data)
      // Заполням форму
      this.flat_edit.apartment = this.flat.apartment
      this.flat_edit.entrances = this.flat.entrances
      this.flat_edit.floor = this.flat.floor
    },
    closeOK() {
      // используй this.$lib.compare(this.flat_edit,this.flat) для отправки только различий
      this.$axios.patch(this.$url.server_project_flat.replace('55555', this.flat.id), this.flat_edit, {zLoader: [this, 'loading']})
          .then(response => {
            this.$lib.restore(this.flat, response.data)
            this.show = false
          })
    },
    // form_close() {
    //
    // },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
  }
}
</script>
